// @mui material components
import { Card, Grid, Icon, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";

// Kaizen Dashboard components
import { gql, useQuery } from "@apollo/client";
import HorizontalBarChart from "components/Charts/BarCharts/HorizontalBarChart";
import DoughnutChart from "components/Charts/DoughnutCharts/CustomDoughnutChart";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import { getUproColor, uproColors } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import * as XLSX from "xlsx";

function ReporteEducativo({ tipo, sedes, tipoCarrera }) {
  const [date, setDate] = useState({
    nombre: "Último año",
    fechaInicio: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
    fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  });
  const [selectedSede, setSelectedSede] = useState();
  const [expandSedes, setExpandSedes] = useState(false);
  const [expandSelector, setExpandSelector] = useState(false);
  const { data: reportes } = useQuery(
    gql`
      query getReportes(
        $fechaInicio: String
        $fechaFin: String
        $tipo: String
        $idSede: ID
        $tipoCarrera: String
      ) {
        reporteEducativo(
          fechaInicio: $fechaInicio
          fechaFin: $fechaFin
          tipo: $tipo
          idSede: $idSede
          tipoCarrera: $tipoCarrera
        ) {
          totalInscripciones
          totalNivelesEducativos {
            cantidad
            nivelEducativo
          }
          totalNivelCursillo {
            nivel
            matematicas
            comprensionTextos
          }
          preferenciaTurnos {
            cantidad
            turno
          }
        }
      }
    `,
    {
      variables: {
        fechaInicio: date.fechaInicio,
        fechaFin: date.fechaFin,
        tipo: tipo,
        idSede: selectedSede?.id || null,
        tipoCarrera,
      },
    }
  );
  const { menu } = useContext(MenuContext);
  return (
    <Card>
      <SoftBox p={3}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
          <SoftTypography variant="h6">Reporte educativo de preinscripciones</SoftTypography>
          <Tooltip
            title={`Desde ${dayjs(date.fechaInicio).format("DD/MM/YYYY")} hasta ${dayjs(
              date.fechaFin
            ).format("DD/MM/YYYY")}`}
            placement="top"
          >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={0.5}>
              <SoftBox display="flex" justifyContent="start" alignItems="center">
                <SoftBox mr={2}>
                  <SoftButton
                    color="uproGreen"
                    circular
                    onClick={(event) => {
                      setExpandSedes(!expandSedes);
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        onClose: () => {
                          setExpandSedes(false);
                        },
                        options: [
                          {
                            name: "Todas las sedes",
                            icon: {
                              icon: !selectedSede ? "location_on" : "location_city",
                              color: !selectedSede
                                ? getUproColor("uproPink")
                                : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setSelectedSede(null);
                            },
                          },
                          ...sedes.map((sede) => ({
                            name: sede.nombre,
                            icon: {
                              icon: selectedSede?.id === sede.id ? "location_on" : "location_city",
                              color:
                                selectedSede?.id === sede.id
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setSelectedSede(sede);
                            },
                          })),
                        ],
                        handleClose: () => {
                          setExpandSelector(false);
                        },
                      });
                    }}
                  >
                    <Icon fontSize="small">apartment</Icon>
                    &nbsp; {selectedSede?.nombre || "Todas las sedes"} &nbsp;
                    <Icon
                      sx={{
                        rotate: expandSedes ? "180deg" : "0deg",
                        transition: "all 0.5s",
                      }}
                    >
                      expand_more
                    </Icon>
                  </SoftButton>
                </SoftBox>
                <SoftBox>
                  <SoftButton
                    color="primary"
                    circular
                    onClick={(event) => {
                      setExpandSelector(!expandSelector);
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        onClose: () => {
                          setExpandSelector(false);
                        },
                        options: [
                          {
                            name: "Hoy",
                            icon: {
                              icon: date.nombre === "Hoy" ? "event_available" : "date_range",
                              color:
                                date.nombre === "Hoy"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Hoy",
                                fechaInicio: dayjs().startOf("day").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Última semana",
                            icon: {
                              icon:
                                date.nombre === "Última semana" ? "event_available" : "date_range",
                              color:
                                date.nombre === "Última semana"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Última semana",
                                fechaInicio: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Último mes",
                            icon: {
                              icon: date.nombre === "Último mes" ? "event_available" : "date_range",
                              color:
                                date.nombre === "Último mes"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Último mes",
                                fechaInicio: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Últimos 3 meses",
                            icon: {
                              icon:
                                date.nombre === "Últimos 3 meses"
                                  ? "event_available"
                                  : "date_range",
                              color:
                                date.nombre === "Últimos 3 meses"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Últimos 3 meses",
                                fechaInicio: dayjs().subtract(3, "month").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Últimos 6 meses",
                            icon: {
                              icon:
                                date.nombre === "Últimos 6 meses"
                                  ? "event_available"
                                  : "date_range",
                              color:
                                date.nombre === "Últimos 6 meses"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Últimos 6 meses",
                                fechaInicio: dayjs().subtract(6, "month").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Último año",
                            icon: {
                              icon: date.nombre === "Último año" ? "event_available" : "date_range",
                              color:
                                date.nombre === "Último año"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Último año",
                                fechaInicio: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Histórico",
                            icon: {
                              icon: date.nombre === "Histórico" ? "event_available" : "date_range",
                              color:
                                date.nombre === "Histórico"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Histórico",
                                fechaInicio: "2021-01-01",
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                        ],
                        handleClose: () => {
                          setExpandSelector(false);
                        },
                      });
                    }}
                  >
                    <Icon fontSize="small">calendar_month</Icon>
                    &nbsp; {date.nombre} &nbsp;
                    <Icon
                      sx={{
                        rotate: expandSelector ? "180deg" : "0deg",
                        transition: "all 0.5s",
                      }}
                    >
                      expand_more
                    </Icon>
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Tooltip>
        </SoftBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Card>
              <SoftBox p={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <SoftTypography variant="h6">
                    Cantidad de preinscriptos según nivel educativo
                  </SoftTypography>
                  <SoftButton
                    size="small"
                    variant="gradient"
                    color="dark"
                    circular
                    iconOnly
                    onClick={(event) => {
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        options: [
                          {
                            name: "Descargar reporte",
                            icon: { icon: "download", color: getUproColor("uproGreen") },
                            onClick: () => {
                              let reporte =
                                reportes?.reporteEducativo?.totalNivelesEducativos || [];
                              let reporteOrdenado = [...reporte].sort(
                                (a, b) => b.cantidad - a.cantidad
                              );

                              let reporteExportar = reporteOrdenado.map((item) => {
                                return tipo === "Preinscripto"
                                  ? {
                                      Nivel: item.nivelEducativo,
                                      "Cantidad de preinscriptos": item.cantidad,
                                    }
                                  : {
                                      Nivel: item.nivelEducativo,
                                      "Cantidad de inscriptos": item.cantidad,
                                    };
                              });
                              const cabecera = [
                                [
                                  `Cantidad de ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } por nivel educativo - ${dayjs().format("DD-MM-YYYY")}`,
                                ],
                              ];

                              const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                              XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                skipHeader: false,
                                origin: -1,
                              });

                              sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                sheet,
                                `Reporte ${dayjs().format("DD-MM-YYYY")}`
                              );

                              XLSX.writeFile(
                                workbook,
                                `Reporte ${
                                  tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                } por nivel educativo - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                              );
                            },
                          },
                        ],
                        handleClose: () => {},
                      });
                    }}
                  >
                    <Icon>more_vert</Icon>
                  </SoftButton>
                </SoftBox>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <SoftBox>
                      {reportes?.reporteEducativo?.totalNivelesEducativos?.map((item, index) => (
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          key={index}
                        >
                          <SoftBox display="flex" alignItems="center">
                            <SoftBox mr={2}>
                              <SoftButton
                                size="small"
                                variant="color"
                                color={uproColors[index]}
                                circular
                                iconOnly
                              />
                            </SoftBox>
                            <SoftTypography variant="button" fontWeight="medium">
                              {`${item.nivelEducativo} (${item.cantidad})`}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      ))}
                      <SoftBox pt={2}>
                        <SoftButton variant="gradient" color="uproGreen" circular>
                          {`Total ${formatMoneyPunto(
                            reportes?.reporteEducativo?.totalInscripciones || 0
                          )}`}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DoughnutChart
                      id={4}
                      title="Cantidad de preinscriptos por genero"
                      chart={{
                        labels: reportes?.reporteEducativo?.totalNivelesEducativos?.map(
                          (item) => item.nivelEducativo
                        ),
                        datasets: {
                          backgroundColors: uproColors,
                          data: reportes?.reporteEducativo?.totalNivelesEducativos?.map(
                            (item) => item.cantidad
                          ),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <SoftBox p={3}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <SoftTypography variant="h6">Nivel académico</SoftTypography>
                  <SoftButton
                    size="small"
                    variant="gradient"
                    color="dark"
                    circular
                    iconOnly
                    onClick={(event) => {
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        options: [
                          {
                            name: "Descargar reporte",
                            icon: { icon: "download", color: getUproColor("uproGreen") },
                            onClick: () => {
                              let reporteMatematicas =
                                reportes?.reporteEducativo?.totalNivelCursillo.map((item) => {
                                  return {
                                    nivel: `${item.nivel} - Matemáticas`,
                                    cantidad: item.matematicas,
                                  };
                                });

                              let reporteComprensionTextos =
                                reportes?.reporteEducativo?.totalNivelCursillo.map((item) => {
                                  return {
                                    nivel: `${item.nivel} - Comprensión de textos`,
                                    cantidad: item.comprensionTextos,
                                  };
                                });

                              let reporte = reporteMatematicas.concat(reporteComprensionTextos);

                              let reporteOrdenado = [...reporte].sort(
                                (a, b) => b.cantidad - a.cantidad
                              );

                              let reporteExportar = reporteOrdenado.map((item) => {
                                return tipo === "Preinscripto"
                                  ? {
                                      Nivel: item.nivel,
                                      "Cantidad de preinscriptos": item.cantidad,
                                    }
                                  : {
                                      Nivel: item.nivel,
                                      "Cantidad de inscriptos": item.cantidad,
                                    };
                              });
                              const cabecera = [
                                [
                                  `Cantidad de ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } por nivel de cursillo - ${dayjs().format("DD-MM-YYYY")}`,
                                ],
                              ];

                              const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                              XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                skipHeader: false,
                                origin: -1,
                              });

                              sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                              const workbook = XLSX.utils.book_new();
                              XLSX.utils.book_append_sheet(
                                workbook,
                                sheet,
                                `Reporte ${dayjs().format("DD-MM-YYYY")}`
                              );

                              XLSX.writeFile(
                                workbook,
                                `Reporte ${
                                  tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                } por nivel de cursillo - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                              );
                            },
                          },
                        ],
                        handleClose: () => {},
                      });
                    }}
                  >
                    <Icon>more_vert</Icon>
                  </SoftButton>
                </SoftBox>
                <HorizontalBarChart
                  chart={{
                    labels: reportes?.reporteEducativo?.totalNivelCursillo?.map(
                      (item) => item.nivel
                    ),
                    datasets: [
                      {
                        label: "Nivel comprensión de textos",
                        color: "uproGreen",
                        data: reportes?.reporteEducativo?.totalNivelCursillo?.map(
                          (item) => item.matematicas
                        ),
                      },
                      {
                        label: "Nivel matemáticas",
                        color: "uproYellow",
                        data: reportes?.reporteEducativo?.totalNivelCursillo?.map(
                          (item) => item.comprensionTextos
                        ),
                      },
                    ],
                  }}
                />
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              sx={{
                height: "100%",
              }}
            >
              <SoftBox
                p={3}
                sx={{
                  height: "100%",
                }}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <SoftBox>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                    <SoftTypography variant="h6">
                      Preferencia de horarios para curso de ingreso
                    </SoftTypography>
                    <SoftButton
                      size="small"
                      variant="gradient"
                      color="dark"
                      circular
                      iconOnly
                      onClick={(event) => {
                        menu({
                          open: event.currentTarget,
                          align: "right",
                          options: [
                            {
                              name: "Descargar reporte",
                              icon: { icon: "download", color: getUproColor("uproGreen") },
                              onClick: () => {
                                let reporte = reportes?.reporteEducativo?.preferenciaTurnos || [];
                                let reporteOrdenado = [...reporte].sort(
                                  (a, b) => b.cantidad - a.cantidad
                                );

                                let reporteExportar = reporteOrdenado.map((item) => {
                                  return tipo === "Preinscripto"
                                    ? {
                                        Turno: item.turno,
                                        "Cantidad de preinscriptos": item.cantidad,
                                      }
                                    : {
                                        Turno: item.turno,
                                        "Cantidad de inscriptos": item.cantidad,
                                      };
                                });
                                const cabecera = [
                                  [
                                    `Cantidad de ${
                                      tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                    } por turno - ${dayjs().format("DD-MM-YYYY")}`,
                                  ],
                                ];

                                const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                                XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                  skipHeader: false,
                                  origin: -1,
                                });

                                sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                                const workbook = XLSX.utils.book_new();
                                XLSX.utils.book_append_sheet(
                                  workbook,
                                  sheet,
                                  `Reporte ${dayjs().format("DD-MM-YYYY")}`
                                );

                                XLSX.writeFile(
                                  workbook,
                                  `Reporte ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } por turno - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                                );
                              },
                            },
                          ],
                          handleClose: () => {},
                        });
                      }}
                    >
                      <Icon>more_vert</Icon>
                    </SoftButton>
                  </SoftBox>
                  <SoftBox>
                    <SoftBox
                      display="flex"
                      sx={{
                        width: "100%",
                        height: "50px",
                        overflow: "hidden",
                        borderRadius: "10px",
                      }}
                    >
                      {reportes?.reporteEducativo?.preferenciaTurnos[0]?.cantidad > 0 && (
                        <Tooltip
                          title={`${
                            reportes?.reporteEducativo?.preferenciaTurnos[0]?.cantidad || 0
                          } preinscriptos prefieren la mañana`}
                          placement="top"
                        >
                          <SoftBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              width:
                                reportes?.reporteEducativo?.preferenciaTurnos.length === 0
                                  ? "25%"
                                  : `${
                                      ((reportes?.reporteEducativo?.preferenciaTurnos[0]
                                        ?.cantidad || 0) *
                                        100) /
                                      reportes?.reporteEducativo?.totalInscripciones
                                    }%`,
                              cursor: "pointer",
                              transition: "all 0.5s",
                              height: "100%",
                              background: "#efeebc",
                              background: "-moz-linear-gradient(top, #efeebc 0%, #61d0cf 100%)",
                              background:
                                "-webkit-gradient(linear, left top, left bottom, color-stop(0%, #efeebc), color-stop(100%, #61d0cf))",
                              background: "-webkit-linear-gradient(top, #efeebc 0%, #61d0cf 100%)",
                              background: "-o-linear-gradient(top, #efeebc 0%, #61d0cf 100%)",
                              background: "-ms-linear-gradient(top, #efeebc 0%, #61d0cf 100%)",
                              background: "linear-gradient(to bottom, #efeebc 0%, #61d0cf 100%)",
                              filter:
                                "progid:DXImageTransform.Microsoft.gradient(startColorstr='#efeebc', endColorstr='#61d0cf',GradientType=0)",
                            }}
                          >
                            <SoftTypography
                              variant="h6"
                              sx={{
                                color: "#333333",
                              }}
                            >
                              {((reportes?.reporteEducativo?.preferenciaTurnos[0]?.cantidad || 0) *
                                100) /
                                reportes?.reporteEducativo?.totalInscripciones >
                                30 || reportes?.reporteEducativo?.preferenciaTurnos.length < 3
                                ? `${
                                    reportes?.reporteEducativo?.preferenciaTurnos.length >= 3
                                      ? (
                                          ((reportes?.reporteEducativo?.preferenciaTurnos[0]
                                            ?.cantidad || 0) *
                                            100) /
                                          reportes?.reporteEducativo?.totalInscripciones
                                        ).toFixed(2)
                                      : "0"
                                  }% Mañana`
                                : `${(
                                    ((reportes?.reporteEducativo?.preferenciaTurnos[0]?.cantidad ||
                                      0) *
                                      100) /
                                    reportes?.reporteEducativo?.totalInscripciones
                                  ).toFixed(2)}%`}
                            </SoftTypography>
                            <Icon
                              sx={{
                                marginLeft: "5px",
                                color: "#333333",
                              }}
                            >
                              wb_twilight
                            </Icon>
                          </SoftBox>
                        </Tooltip>
                      )}
                      {reportes?.reporteEducativo?.preferenciaTurnos[1]?.cantidad > 0 && (
                        <Tooltip
                          title={`${
                            reportes?.reporteEducativo?.preferenciaTurnos[1]?.cantidad || 0
                          } preinscriptos prefieren la tarde`}
                          placement="top"
                        >
                          <SoftBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              width:
                                reportes?.reporteEducativo?.preferenciaTurnos.length < 3
                                  ? "25%"
                                  : `${
                                      (reportes?.reporteEducativo?.preferenciaTurnos[1]?.cantidad *
                                        100) /
                                      reportes?.reporteEducativo?.totalInscripciones
                                    }%`,
                              cursor: "pointer",
                              transition: "all 0.5s",
                              height: "100%",
                              background: "#f18448",
                              background: "-moz-linear-gradient(top, #f18448 0%, #ffd364 100%)",
                              background:
                                "-webkit-gradient(linear, left top, left bottom, color-stop(0%, #f18448), color-stop(100%, #ffd364))",
                              background: "-webkit-linear-gradient(top, #f18448 0%, #ffd364 100%)",
                              background: "-o-linear-gradient(top, #f18448 0%, #ffd364 100%)",
                              background: "-ms-linear-gradient(top, #f18448 0%, #ffd364 100%)",
                              background: "linear-gradient(to bottom, #f18448 0%, #ffd364 100%)",
                              filter:
                                "progid:DXImageTransform.Microsoft.gradient(startColorstr='#f18448', endColorstr='#ffd364', GradientType=0)",
                            }}
                          >
                            <SoftTypography
                              variant="h6"
                              sx={{
                                color: "#333333",
                              }}
                            >
                              {((reportes?.reporteEducativo?.preferenciaTurnos[1]?.cantidad || 0) *
                                100) /
                                reportes?.reporteEducativo?.totalInscripciones >
                                30 || reportes?.reporteEducativo?.preferenciaTurnos.length < 3
                                ? `${
                                    reportes?.reporteEducativo?.preferenciaTurnos.length >= 3
                                      ? (
                                          ((reportes?.reporteEducativo?.preferenciaTurnos[1]
                                            ?.cantidad || 0) *
                                            100) /
                                          reportes?.reporteEducativo?.totalInscripciones
                                        ).toFixed(2)
                                      : "0"
                                  }% Tarde`
                                : `${(
                                    ((reportes?.reporteEducativo?.preferenciaTurnos[1]?.cantidad ||
                                      0) *
                                      100) /
                                    reportes?.reporteEducativo?.totalInscripciones
                                  ).toFixed(2)}%`}
                            </SoftTypography>
                            <Icon
                              color="secondary"
                              sx={{
                                marginLeft: "5px",
                                color: "#333333",
                              }}
                            >
                              wb_sunny
                            </Icon>
                          </SoftBox>
                        </Tooltip>
                      )}
                      {reportes?.reporteEducativo?.preferenciaTurnos[2]?.cantidad > 0 && (
                        <Tooltip
                          title={`${reportes?.reporteEducativo?.preferenciaTurnos[2]?.cantidad} preinscriptos prefieren la noche`}
                          placement="top"
                        >
                          <SoftBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              width:
                                reportes?.reporteEducativo?.preferenciaTurnos.length < 3
                                  ? "25%"
                                  : `${
                                      (reportes?.reporteEducativo?.preferenciaTurnos[2]?.cantidad *
                                        100) /
                                      reportes?.reporteEducativo?.totalInscripciones
                                    }%`,
                              cursor: "pointer",
                              transition: "all 0.5s",
                              height: "100%",
                              background: "#040b3c",
                              background: "-moz-linear-gradient(top, #040b3c 0%, #233072 100%)",
                              background:
                                "-webkit-gradient(linear, left top, left bottom, color-stop(0%, #040b3c), color-stop(100%, #233072))",
                              background: "-webkit-linear-gradient(top, #040b3c 0%, #233072 100%)",
                              background: "-o-linear-gradient(top, #040b3c 0%, #233072 100%)",
                              background: "-ms-linear-gradient(top, #040b3c 0%, #233072 100%)",
                              background: "linear-gradient(to bottom, #040b3c 0%, #233072 100%)",
                              filter:
                                "progid:DXImageTransform.Microsoft.gradient(startColorstr='#040b3c', endColorstr='#233072', GradientType=0)",
                            }}
                          >
                            <SoftTypography
                              variant="h6"
                              sx={{
                                color: "#ffffff",
                              }}
                            >
                              {(reportes?.reporteEducativo?.preferenciaTurnos[2]?.cantidad * 100) /
                                reportes?.reporteEducativo?.totalInscripciones >
                                30 || reportes?.reporteEducativo?.preferenciaTurnos.length < 3
                                ? `${
                                    reportes?.reporteEducativo?.preferenciaTurnos.length >= 3
                                      ? (
                                          (reportes?.reporteEducativo?.preferenciaTurnos[2]
                                            ?.cantidad *
                                            100) /
                                          reportes?.reporteEducativo?.totalInscripciones
                                        ).toFixed(2)
                                      : "0"
                                  }% Noche`
                                : `${(
                                    (reportes?.reporteEducativo?.preferenciaTurnos[2]?.cantidad *
                                      100) /
                                    reportes?.reporteEducativo?.totalInscripciones
                                  ).toFixed(2)}%`}
                            </SoftTypography>
                            <Icon
                              color="secondary"
                              sx={{
                                marginLeft: "5px",
                                color: "#ffffff",
                              }}
                            >
                              brightness_3
                            </Icon>
                          </SoftBox>
                        </Tooltip>
                      )}
                      {reportes?.reporteEducativo?.preferenciaTurnos[3]?.cantidad > 0 && (
                        <Tooltip
                          title={`${
                            reportes?.reporteEducativo?.preferenciaTurnos[3]?.cantidad || 0
                          } preinscriptos eligieron indistinto`}
                          placement="top"
                        >
                          <SoftBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              width:
                                reportes?.reporteEducativo?.preferenciaTurnos.length < 3
                                  ? "25%"
                                  : `${
                                      ((reportes?.reporteEducativo?.preferenciaTurnos[3]
                                        ?.cantidad || 0) *
                                        100) /
                                      reportes?.reporteEducativo?.totalInscripciones
                                    }%`,
                              cursor: "pointer",
                              transition: "all 0.5s",
                              height: "100%",
                              background: "#371a79",
                              background: "-moz-linear-gradient(top, #371a79 0%, #713684 100%)",
                              background: "-webkit-linear-gradient(top, #371a79 0%, #713684 100%)",
                              background: "-o-linear-gradient(top, #371a79 0%, #713684 100%)",
                              background: "-ms-linear-gradient(top, #371a79 0%, #713684 100%)",
                              background: "linear-gradient(to bottom, #371a79 0%, #713684 100%)",
                              filter:
                                "progid:DXImageTransform.Microsoft.gradient(startColorstr='#371a79', endColorstr='#713684', GradientType=0)",
                            }}
                          >
                            <SoftTypography
                              variant="h6"
                              sx={{
                                color: "#ffffff",
                              }}
                            >
                              {((reportes?.reporteEducativo?.preferenciaTurnos[3]?.cantidad || 0) *
                                100) /
                                reportes?.reporteEducativo?.totalInscripciones >
                                30 || reportes?.reporteEducativo?.preferenciaTurnos.length < 3
                                ? `${
                                    reportes?.reporteEducativo?.preferenciaTurnos.length >= 3
                                      ? (
                                          ((reportes?.reporteEducativo?.preferenciaTurnos[3]
                                            ?.cantidad || 0) *
                                            100) /
                                          reportes?.reporteEducativo?.totalInscripciones
                                        ).toFixed(2)
                                      : "0"
                                  }% Indistinto`
                                : `${(
                                    ((reportes?.reporteEducativo?.preferenciaTurnos[3]?.cantidad ||
                                      0) *
                                      100) /
                                    reportes?.reporteEducativo?.totalInscripciones
                                  ).toFixed(2)}%`}
                            </SoftTypography>
                            <Icon
                              color="secondary"
                              sx={{
                                marginLeft: "5px",
                                color: "#ffffff",
                              }}
                            >
                              help
                            </Icon>
                          </SoftBox>
                        </Tooltip>
                      )}
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

ReporteEducativo.defaultProps = {
  tipo: "Preinscripto",
  sedes: [],
  tipoCarrera: "Carrera",
};

ReporteEducativo.propTypes = {
  tipo: PropTypes.string,
  sedes: PropTypes.array,
  tipoCarrera: PropTypes.string,
};

export default ReporteEducativo;
