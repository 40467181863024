// @mui material components
import {
  Card,
  Collapse,
  Fade,
  Grid,
  Icon,
  Modal,
  Skeleton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// Kaizen Dashboard components
import { gql, useLazyQuery } from "@apollo/client";
import PieChart from "components/Charts/PieChart";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import { getUproColor, uproColors } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "70%" },
  overflow: "auto",
  py: 4,
};

export default function ModalReporteComision({ open, handleClose }) {
  const [materias, setMaterias] = useState([]);
  const [selectedSede, setSelectedSede] = useState(null);
  const [selectedMateria, setSelectedMateria] = useState(null);
  const [expanded, setExpanded] = useState(["Calendario"]);
  const [filterComisionesVacias, setFilterComisionesVacias] = useState(false);
  const [expandSedes, setExpandSedes] = useState(false);
  const [expandMateria, setExpandMateria] = useState(false);
  const [reporteCapacidad, setReporteCapacidad] = useState({
    disponible: 0,
    ocupado: 0,
    total: 0,
  });
  const [reporteNivel, setReporteNivel] = useState({
    bajo: 0,
    medio: 0,
    alto: 0,
  });
  const { menu } = useContext(MenuContext);

  const [getCursos] = useLazyQuery(
    gql`
      query getCursos {
        carreras(filter: { tipo: ["Curso de Ingreso"] }) {
          id
          tipo
          nombre
          codigo
          planes {
            niveles {
              materias {
                id
                nombre
                codigo
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [getSedes, { data: sedes }] = useLazyQuery(
    gql`
      query getSedes {
        sedes {
          id
          nombre
          aulas {
            id
            nombre
            capacidad
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const [getComisiones, { loading, data: comisiones }] = useLazyQuery(
    gql`
      query getComisiones($filter: JSON) {
        comisiones(filter: $filter) {
          id
          nombre
          codigo
          color
          nivel
          cantidadInscriptos
          capacidad
          sede {
            id
          }
          docente {
            id
          }
          materia {
            id
            nombre
          }
          horarios {
            dia
            aula {
              id
              nombre
            }
            capacidad
            horarioInicio
            horarioFin
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (open) {
      getCursos().then((data) => {
        const materias = data?.data?.carreras?.reduce((acc, carrera) => {
          const materias = carrera.planes[0].niveles[0].materias.map((materia) => ({
            ...materia,
            carrera: carrera.nombre,
          }));
          return [...acc, ...materias];
        }, []);
        setMaterias(materias);
        setSelectedMateria(materias[0]);
      });
      getSedes().then((data) => {
        setSelectedSede(data?.data?.sedes?.[0]);
      });
    }
  }, [open]);

  useEffect(() => {
    if (open && selectedSede && selectedMateria) {
      getComisiones({
        variables: {
          filter: { idSede: selectedSede?.id, idMateria: selectedMateria?.id },
        },
      });
    }
  }, [open, selectedSede, selectedMateria]);

  useEffect(() => {
    let total = 0;
    let ocupado = 0;
    let bajo = 0;
    let medio = 0;
    let alto = 0;
    comisiones?.comisiones?.forEach((comision) => {
      total += comision.capacidad;
      ocupado += comision.cantidadInscriptos;
      switch (comision.nivel) {
        case "Bajo":
          bajo += 1;
          break;
        case "Medio":
          medio += 1;
          break;
        case "Alto":
          alto += 1;
          break;
      }
    });
    setReporteCapacidad({
      total,
      ocupado,
      disponible: total - ocupado,
    });
    setReporteNivel({
      bajo,
      medio,
      alto,
    });
  }, [comisiones]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
            <SoftTypography variant="h6">Reporte de comisiones</SoftTypography>
            <SoftBox display="flex" alignItems="center">
              <SoftBox mr={2}>
                <SoftButton
                  color="uproYellow"
                  circular
                  onClick={(event) => {
                    setExpandMateria(!expandMateria);
                    menu({
                      open: event.currentTarget,
                      align: "right",
                      onClose: () => {
                        setExpandMateria(false);
                      },
                      options: [
                        ...materias.map((materia) => ({
                          name: materia.nombre,
                          icon: {
                            icon:
                              selectedMateria?.id === materia.id
                                ? "import_contacts"
                                : "auto_stories",
                            color:
                              selectedMateria?.id === materia.id
                                ? getUproColor("uproYellow")
                                : getUproColor("uproGreen"),
                          },
                          onClick: () => {
                            setSelectedMateria(materia);
                          },
                        })),
                      ],
                      handleClose: () => {
                        setExpandSelector(false);
                      },
                    });
                  }}
                >
                  <Icon fontSize="small">auto_stories</Icon>
                  &nbsp; {selectedMateria?.nombre || "Todas las materias"} &nbsp;
                  <Icon
                    sx={{
                      rotate: expandMateria ? "180deg" : "0deg",
                      transition: "all 0.5s",
                    }}
                  >
                    expand_more
                  </Icon>
                </SoftButton>
              </SoftBox>
              <SoftBox mr={2}>
                <SoftButton
                  color="uproGreen"
                  circular
                  onClick={(event) => {
                    setExpandSedes(!expandSedes);
                    menu({
                      open: event.currentTarget,
                      align: "right",
                      onClose: () => {
                        setExpandSedes(false);
                      },
                      options: [
                        ...sedes?.sedes?.map((sede) => ({
                          name: sede.nombre,
                          icon: {
                            icon: selectedSede?.id === sede.id ? "location_on" : "location_city",
                            color:
                              selectedSede?.id === sede.id
                                ? getUproColor("uproPink")
                                : getUproColor("uproGreen"),
                          },
                          onClick: () => {
                            setSelectedSede(sede);
                          },
                        })),
                      ],
                      handleClose: () => {
                        setExpandSelector(false);
                      },
                    });
                  }}
                >
                  <Icon fontSize="small">apartment</Icon>
                  &nbsp; {selectedSede?.nombre || "Todas las sedes"} &nbsp;
                  <Icon
                    sx={{
                      rotate: expandSedes ? "180deg" : "0deg",
                      transition: "all 0.5s",
                    }}
                  >
                    expand_more
                  </Icon>
                </SoftButton>
              </SoftBox>
              <SoftBox>
                <Icon
                  sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                    fontSize: `${size.lg} !important`,
                    fontWeight: `${fontWeightBold} !important`,
                    stroke: dark.main,
                    strokeWidth: "2px",
                    cursor: "pointer",
                  })}
                  onClick={handleClose}
                >
                  close
                </Icon>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox px={3} pb={3}>
            <SoftBox pt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <SoftBox p={3}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        pb={2}
                      >
                        <SoftTypography variant="h6">Capacidad de comisiones</SoftTypography>
                      </SoftBox>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6}>
                          <SoftBox>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={uproColors[0]}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                {loading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  <SoftTypography variant="button" fontWeight="medium">
                                    {`Cantidad de lugares ocupados: ${reporteCapacidad?.ocupado}`}
                                  </SoftTypography>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={uproColors[1]}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                {loading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  <SoftTypography variant="button" fontWeight="medium">
                                    {`Cantidad de lugares disponibles: ${reporteCapacidad?.disponible}`}
                                  </SoftTypography>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox pt={2}>
                              {loading ? (
                                <Skeleton width={100} />
                              ) : (
                                <SoftButton color="uproBlue" circular>
                                  {`Estado ${
                                    reporteCapacidad?.ocupado
                                      ? (
                                          (reporteCapacidad?.ocupado / reporteCapacidad?.total) *
                                          100
                                        ).toFixed(2)
                                      : 0
                                  }%`}
                                </SoftButton>
                              )}
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <PieChart
                            id={new Date().getTime()}
                            title={"Test"}
                            chart={{
                              labels: ["Ocupado", "Disponible"],
                              datasets: {
                                backgroundColors: [uproColors[0], uproColors[1]],
                                data: [reporteCapacidad?.ocupado, reporteCapacidad?.disponible],
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <SoftBox p={3}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        pb={2}
                      >
                        <SoftTypography variant="h6">Nivel de las comisiones</SoftTypography>
                      </SoftBox>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6}>
                          <SoftBox>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={getUproColor("uproPink")}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                {loading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  <SoftTypography variant="button" fontWeight="medium">
                                    {`Bajo: ${reporteNivel?.bajo}`}
                                  </SoftTypography>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={getUproColor("uproYellow")}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                {loading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  <SoftTypography variant="button" fontWeight="medium">
                                    {`Medio: ${reporteNivel?.medio}`}
                                  </SoftTypography>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={getUproColor("uproGreen")}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                {loading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  <SoftTypography variant="button" fontWeight="medium">
                                    {`Alto: ${reporteNivel?.alto}`}
                                  </SoftTypography>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox pt={2}>
                              {loading ? (
                                <Skeleton width={100} />
                              ) : (
                                <SoftButton color="uproBlue" circular>
                                  {`Total ${comisiones?.comisiones?.length}`}
                                </SoftButton>
                              )}
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <PieChart
                            id={new Date().getTime()}
                            title={"Test"}
                            chart={{
                              labels: ["Bajo", "Medio", "Alto"],
                              datasets: {
                                backgroundColors: [
                                  getUproColor("uproPink"),
                                  getUproColor("uproYellow"),
                                  getUproColor("uproGreen"),
                                ],
                                data: [reporteNivel?.bajo, reporteNivel?.medio, reporteNivel?.alto],
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                      py={1}
                      sx={{
                        borderBottom: !expanded.includes("Calendario")
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon
                            sx={{
                              color: getUproColor("uproYellow"),
                            }}
                          >
                            calendar_today
                          </Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          Capacidad de comisiones según horario
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                        <SoftBox mr={2}>
                          <SoftButton
                            color="uproGreen"
                            circular
                            onClick={() => setFilterComisionesVacias(!filterComisionesVacias)}
                          >
                            <Icon>{!filterComisionesVacias ? "visibility_off" : "visibility"}</Icon>
                            &nbsp;
                            {!filterComisionesVacias
                              ? "Ocultar comisiones vacías"
                              : "Mostrar comisiones vacías"}
                          </SoftButton>
                        </SoftBox>
                        <SoftBox>
                          <Tooltip
                            title={expanded.includes("Calendario") ? "Ocultar" : "Mostrar"}
                            placement="top"
                          >
                            <SoftButton
                              color="primary"
                              circular
                              iconOnly
                              onClick={() =>
                                setExpanded(
                                  expanded.includes("Calendario")
                                    ? expanded.filter((i) => i !== "Calendario")
                                    : [...expanded, "Calendario"]
                                )
                              }
                            >
                              <Icon
                                sx={{
                                  rotate: expanded.includes("Calendario") ? "180deg" : "0deg",
                                  transition: "all 0.5s",
                                }}
                              >
                                expand_more
                              </Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={expanded.includes("Calendario")}>
                      <SoftBox sx={{ maxHeight: "50vh", overflowY: "scroll" }}>
                        <Table stickyHeader>
                          <TableHead
                            sx={{
                              display: "revert !important",
                            }}
                          >
                            <TableRow>
                              <TableCell>&nbsp;</TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Lunes
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Martes
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Miércoles
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Jueves
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                Viernes
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {new Array(17).fill(0).map((_, index) => {
                            const hora = dayjs()
                              .startOf("day")
                              .add(index + 7, "hour");

                            const comisionesSede = comisiones?.comisiones?.filter(
                              (comision) => comision.sede.id === selectedSede?.id
                            );

                            const filtrarComisionesPorDiaYHora = (dia) => {
                              let response = {
                                capacidad: 0,
                                cantidadInscriptos: 0,
                                porcentaje: 0,
                              };
                              const comisionesData = comisionesSede?.filter((comision) =>
                                comision.horarios.some(
                                  (horario) =>
                                    horario.dia === dia &&
                                    (dayjs(horario.horarioInicio, "HH:mm").isSame(hora, "hour") ||
                                      (dayjs(horario.horarioInicio, "HH:mm").isBefore(
                                        hora,
                                        "hour"
                                      ) &&
                                        dayjs(horario.horarioFin, "HH:mm").isAfter(hora, "hour")))
                                )
                              );
                              if (comisionesData?.length > 0) {
                                comisionesData.forEach((comision) => {
                                  response = {
                                    capacidad:
                                      response.capacidad +
                                      comision.horarios.find(
                                        (horario) =>
                                          horario.dia === dia &&
                                          (dayjs(horario.horarioInicio, "HH:mm").isSame(
                                            hora,
                                            "hour"
                                          ) ||
                                            (dayjs(horario.horarioInicio, "HH:mm").isBefore(
                                              hora,
                                              "hour"
                                            ) &&
                                              dayjs(horario.horarioFin, "HH:mm").isAfter(
                                                hora,
                                                "hour"
                                              )))
                                      ).capacidad,
                                    cantidadInscriptos:
                                      response.cantidadInscriptos + comision.cantidadInscriptos,
                                  };
                                });
                              }
                              response.porcentaje = Math.round(
                                (response.cantidadInscriptos / response.capacidad) * 100
                              );
                              return response;
                            };

                            return (
                              <TableRow key={index}>
                                <TableCell>{hora.format("HH:mm")}</TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Lunes")?.capacidad &&
                                      ((filterComisionesVacias &&
                                        filtrarComisionesPorDiaYHora("Lunes")?.cantidadInscriptos >
                                          0) ||
                                        !filterComisionesVacias)
                                        ? filtrarComisionesPorDiaYHora("Lunes")?.porcentaje > 75
                                          ? getUproColor("uproPink")
                                          : filtrarComisionesPorDiaYHora("Lunes")?.porcentaje > 50
                                          ? getUproColor("uproYellow")
                                          : filtrarComisionesPorDiaYHora("Lunes")?.porcentaje > 25
                                          ? getUproColor("uproGreen")
                                          : filtrarComisionesPorDiaYHora("Lunes")
                                              ?.cantidadInscriptos > 0
                                          ? getUproColor("uproBlue")
                                          : getUproColor()
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  <Tooltip
                                    placement="top"
                                    title={
                                      filtrarComisionesPorDiaYHora("Lunes")?.capacidad &&
                                      ((filterComisionesVacias &&
                                        filtrarComisionesPorDiaYHora("Lunes")?.cantidadInscriptos >
                                          0) ||
                                        !filterComisionesVacias)
                                        ? `${
                                            filtrarComisionesPorDiaYHora("Lunes")
                                              ?.cantidadInscriptos
                                          } / ${
                                            filtrarComisionesPorDiaYHora("Lunes")?.capacidad
                                          } (${filtrarComisionesPorDiaYHora("Lunes")?.porcentaje}%)`
                                        : ""
                                    }
                                  >
                                    <SoftBox
                                      display="flex"
                                      flexDirection="column"
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >
                                        {filtrarComisionesPorDiaYHora("Lunes")?.capacidad &&
                                        ((filterComisionesVacias &&
                                          filtrarComisionesPorDiaYHora("Lunes")
                                            ?.cantidadInscriptos > 0) ||
                                          !filterComisionesVacias)
                                          ? `${
                                              filtrarComisionesPorDiaYHora("Lunes")
                                                ?.cantidadInscriptos
                                            } / ${filtrarComisionesPorDiaYHora("Lunes")?.capacidad}`
                                          : ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  </Tooltip>
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Martes")?.capacidad &&
                                      ((filterComisionesVacias &&
                                        filtrarComisionesPorDiaYHora("Martes")?.cantidadInscriptos >
                                          0) ||
                                        !filterComisionesVacias)
                                        ? filtrarComisionesPorDiaYHora("Martes")?.porcentaje > 75
                                          ? getUproColor("uproPink")
                                          : filtrarComisionesPorDiaYHora("Martes")?.porcentaje > 50
                                          ? getUproColor("uproYellow")
                                          : filtrarComisionesPorDiaYHora("Martes")?.porcentaje > 25
                                          ? getUproColor("uproGreen")
                                          : filtrarComisionesPorDiaYHora("Martes")
                                              ?.cantidadInscriptos > 0
                                          ? getUproColor("uproBlue")
                                          : getUproColor()
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  <Tooltip
                                    placement="top"
                                    title={
                                      filtrarComisionesPorDiaYHora("Martes")?.capacidad &&
                                      ((filterComisionesVacias &&
                                        filtrarComisionesPorDiaYHora("Martes")?.cantidadInscriptos >
                                          0) ||
                                        !filterComisionesVacias)
                                        ? `${
                                            filtrarComisionesPorDiaYHora("Martes")
                                              ?.cantidadInscriptos
                                          } / ${
                                            filtrarComisionesPorDiaYHora("Martes")?.capacidad
                                          } (${
                                            filtrarComisionesPorDiaYHora("Martes")?.porcentaje
                                          }%)`
                                        : ""
                                    }
                                  >
                                    <SoftBox
                                      display="flex"
                                      flexDirection="column"
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >
                                        {filtrarComisionesPorDiaYHora("Martes")?.capacidad &&
                                        ((filterComisionesVacias &&
                                          filtrarComisionesPorDiaYHora("Martes")
                                            ?.cantidadInscriptos > 0) ||
                                          !filterComisionesVacias)
                                          ? `${
                                              filtrarComisionesPorDiaYHora("Martes")
                                                ?.cantidadInscriptos
                                            } / ${
                                              filtrarComisionesPorDiaYHora("Martes")?.capacidad
                                            }`
                                          : ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  </Tooltip>
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Miércoles")?.capacidad &&
                                      ((filterComisionesVacias &&
                                        filtrarComisionesPorDiaYHora("Miércoles")
                                          ?.cantidadInscriptos > 0) ||
                                        !filterComisionesVacias)
                                        ? filtrarComisionesPorDiaYHora("Miércoles")?.porcentaje > 75
                                          ? getUproColor("uproPink")
                                          : filtrarComisionesPorDiaYHora("Miércoles")?.porcentaje >
                                            50
                                          ? getUproColor("uproYellow")
                                          : filtrarComisionesPorDiaYHora("Miércoles")?.porcentaje >
                                            25
                                          ? getUproColor("uproGreen")
                                          : filtrarComisionesPorDiaYHora("Miércoles")
                                              ?.cantidadInscriptos > 0
                                          ? getUproColor("uproBlue")
                                          : getUproColor()
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  <Tooltip
                                    placement="top"
                                    title={
                                      filtrarComisionesPorDiaYHora("Miércoles")?.capacidad &&
                                      ((filterComisionesVacias &&
                                        filtrarComisionesPorDiaYHora("Miércoles")
                                          ?.cantidadInscriptos > 0) ||
                                        !filterComisionesVacias)
                                        ? `${
                                            filtrarComisionesPorDiaYHora("Miércoles")
                                              ?.cantidadInscriptos
                                          } / ${
                                            filtrarComisionesPorDiaYHora("Miércoles")?.capacidad
                                          } (${
                                            filtrarComisionesPorDiaYHora("Miércoles")?.porcentaje
                                          }%)`
                                        : ""
                                    }
                                  >
                                    <SoftBox
                                      display="flex"
                                      flexDirection="column"
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >
                                        {filtrarComisionesPorDiaYHora("Miércoles")?.capacidad &&
                                        ((filterComisionesVacias &&
                                          filtrarComisionesPorDiaYHora("Miércoles")
                                            ?.cantidadInscriptos > 0) ||
                                          !filterComisionesVacias)
                                          ? `${
                                              filtrarComisionesPorDiaYHora("Miércoles")
                                                ?.cantidadInscriptos
                                            } / ${
                                              filtrarComisionesPorDiaYHora("Miércoles")?.capacidad
                                            }`
                                          : ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  </Tooltip>
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Jueves")?.capacidad &&
                                      ((filterComisionesVacias &&
                                        filtrarComisionesPorDiaYHora("Jueves")?.cantidadInscriptos >
                                          0) ||
                                        !filterComisionesVacias)
                                        ? filtrarComisionesPorDiaYHora("Jueves")?.porcentaje > 75
                                          ? getUproColor("uproPink")
                                          : filtrarComisionesPorDiaYHora("Jueves")?.porcentaje > 50
                                          ? getUproColor("uproYellow")
                                          : filtrarComisionesPorDiaYHora("Jueves")?.porcentaje > 25
                                          ? getUproColor("uproGreen")
                                          : filtrarComisionesPorDiaYHora("Jueves")
                                              ?.cantidadInscriptos > 0
                                          ? getUproColor("uproBlue")
                                          : getUproColor()
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  <Tooltip
                                    placement="top"
                                    title={
                                      filtrarComisionesPorDiaYHora("Jueves")?.capacidad &&
                                      ((filterComisionesVacias &&
                                        filtrarComisionesPorDiaYHora("Jueves")?.cantidadInscriptos >
                                          0) ||
                                        !filterComisionesVacias)
                                        ? `${
                                            filtrarComisionesPorDiaYHora("Jueves")
                                              ?.cantidadInscriptos
                                          } / ${
                                            filtrarComisionesPorDiaYHora("Jueves")?.capacidad
                                          } (${
                                            filtrarComisionesPorDiaYHora("Jueves")?.porcentaje
                                          }%)`
                                        : ""
                                    }
                                  >
                                    <SoftBox
                                      display="flex"
                                      flexDirection="column"
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >
                                        {filtrarComisionesPorDiaYHora("Jueves")?.capacidad &&
                                        ((filterComisionesVacias &&
                                          filtrarComisionesPorDiaYHora("Jueves")
                                            ?.cantidadInscriptos > 0) ||
                                          !filterComisionesVacias)
                                          ? `${
                                              filtrarComisionesPorDiaYHora("Jueves")
                                                ?.cantidadInscriptos
                                            } / ${
                                              filtrarComisionesPorDiaYHora("Jueves")?.capacidad
                                            }`
                                          : ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  </Tooltip>
                                </TableCell>

                                <TableCell
                                  sx={{
                                    backgroundColor:
                                      filtrarComisionesPorDiaYHora("Viernes")?.capacidad &&
                                      ((filterComisionesVacias &&
                                        filtrarComisionesPorDiaYHora("Viernes")
                                          ?.cantidadInscriptos > 0) ||
                                        !filterComisionesVacias)
                                        ? filtrarComisionesPorDiaYHora("Viernes")?.porcentaje > 75
                                          ? getUproColor("uproPink")
                                          : filtrarComisionesPorDiaYHora("Viernes")?.porcentaje > 50
                                          ? getUproColor("uproYellow")
                                          : filtrarComisionesPorDiaYHora("Viernes")?.porcentaje > 25
                                          ? getUproColor("uproGreen")
                                          : filtrarComisionesPorDiaYHora("Viernes")
                                              ?.cantidadInscriptos > 0
                                          ? getUproColor("uproBlue")
                                          : getUproColor()
                                        : "white",
                                    textAlign: "center",
                                  }}
                                >
                                  <Tooltip
                                    placement="top"
                                    title={
                                      filtrarComisionesPorDiaYHora("Viernes")?.capacidad &&
                                      ((filterComisionesVacias &&
                                        filtrarComisionesPorDiaYHora("Viernes")
                                          ?.cantidadInscriptos > 0) ||
                                        !filterComisionesVacias)
                                        ? `${
                                            filtrarComisionesPorDiaYHora("Viernes")
                                              ?.cantidadInscriptos
                                          } / ${
                                            filtrarComisionesPorDiaYHora("Viernes")?.capacidad
                                          } (${
                                            filtrarComisionesPorDiaYHora("Viernes")?.porcentaje
                                          }%)`
                                        : ""
                                    }
                                  >
                                    <SoftBox
                                      display="flex"
                                      flexDirection="column"
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="white"
                                      >
                                        {filtrarComisionesPorDiaYHora("Viernes")?.capacidad &&
                                        ((filterComisionesVacias &&
                                          filtrarComisionesPorDiaYHora("Viernes")
                                            ?.cantidadInscriptos > 0) ||
                                          !filterComisionesVacias)
                                          ? `${
                                              filtrarComisionesPorDiaYHora("Viernes")
                                                ?.cantidadInscriptos
                                            } / ${
                                              filtrarComisionesPorDiaYHora("Viernes")?.capacidad
                                            }`
                                          : ""}
                                      </SoftTypography>
                                    </SoftBox>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </Table>
                      </SoftBox>
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalReporteComision.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
